import React from 'react';
import styled from 'styled-components';

import Hero from './Hero';
import SubNav from './SubNav';
import Content from './Content';

const Page = ({ hero, _rawContent, subNav }) => {
  // console.log({ _rawContent });
  return (
    <PageWrapper className="page-wrapper">
      {hero && <Hero {...hero} hasSubNav={!!subNav.length} />}
      {subNav.length > 0 && <SubNav nav={subNav} />}
      <Content className="page-content">{_rawContent}</Content>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  .page-content {
    padding: 1rem;
  }
`;

export default Page;
