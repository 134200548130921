import React from 'react';
import styled from 'styled-components';

import NavItem from './NavItem';
import { media } from './theme';

const SubNav = ({ nav }) => {
  // console.log(nav);
  return (
    <Nav className="subnav">
      <ul className="subnav__list">
        {nav.map(item => {
          return (
            <li className="subnav__list-item" key={item._key}>
              <NavItem className="subnav" {...item} />
            </li>
          );
        })}
      </ul>
    </Nav>
  );
};

const Nav = styled.nav`
  background: ${({ theme }) => theme.orange};
  .subnav {
    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-around;
      color: white;
      padding: 2rem;
      flex-flow: row wrap;
      ${media.break`

      `}
    }
    &__list-item {
      margin-left: 1rem;
      margin-right: 1rem;
      display: block;
      /* text-transform: uppercase;
      font-weight: ${({ theme }) => theme.font.bold}; */
    }
  }
`;

export default SubNav;
