import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Wrapper';
import Page from '../components/Page';

const page = ({ data }) => {
  return (
    <Wrapper className="page">
      <Page {...data.sanityPage} />
    </Wrapper>
  );
};

export const PageQuery = graphql`
  query pageQuery($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      id
      title
      hero {
        heroText
        heroSubText
        heroImage {
          alt
          asset {
            assetId
            id
            sha1hash
            _id
            _rev
            _type
            fluid(maxWidth: 1600) {
              ...GatsbySanityImageFluid
            }
            metadata {
              lqip
              dimensions {
                width
                height
                aspectRatio
              }
            }
            url
          }
          crop {
            top
            left
            bottom
            right
          }
        }
      }
      subNav {
        _key
        label
        link {
          ... on SanityHome {
            slug {
              current
            }
            _type
          }
          ... on SanityLineupPage {
            id
            _type
          }
          ... on SanityPage {
            id
            _type
            slug {
              current
            }
          }
        }
        externalUrl
        anchor
      }
      _rawContent(resolveReferences: { maxDepth: 50 })

      # blocks {
      #   _key
      #   heading
      #   slug {
      #     current
      #   }
      #   image {
      #     alt
      #     asset {
      #       fluid(maxWidth: 800) {
      #         ...GatsbySanityImageFluid
      #       }
      #       metadata {
      #         dimensions {
      #           width
      #           height
      #         }
      #       }
      #     }
      #     crop {
      #       top
      #       left
      #       bottom
      #       right
      #     }
      #   }
      #   _rawBody(resolveReferences: { maxDepth: 10 })
      #   link {
      #     label
      #     url
      #   }
      # }
    }
  }
`;

export default page;
